<template>
	<div class="old-form">
		<el-form label-width="100px" :model="form" ref="form" :rules="rules">
			<el-form-item label="工程标题" prop="title">
				<el-input v-model="form.title" placeholder="请输入工程标题" />
			</el-form-item>
			<el-form-item label="seo关键词">
				<el-input v-model="form.keywords" placeholder="请输入seo关键词" />
			</el-form-item>
			<el-form-item label="seo描述">
				<el-input v-model="form.description" placeholder="请输入seo描述" />
			</el-form-item>
			<el-form-item label="分类" prop="catid">
				<el-cascader v-model="form.catid" :options="options" :props="catidProps" @change="handleChange" />
			</el-form-item>
			<el-form-item label="自定义分类">
				<el-cascader v-model="form.mycatid" :options="options2" :props="catidProps2" @change="handleChange2" />
				<el-button class="add-catid" @click="goAddCatid">添加分类</el-button>
			</el-form-item>
			<el-form-item label="封面图" prop="imageUrl">
				<el-upload class="avatar-uploader" :action="picUrl" :show-file-list="false"
					element-loading-text="拼命加载中……"
					:on-progress="change"
					v-loading.fullscreen.lock="loading"
					element-loading-background="rgba(250, 250, 250, 0.3)"
					:show-file-list="false" :data="fileInfo"
					:before-upload="beforeAvatarUpload"
					:on-success="handleAvatarSuccess" accept=".jpg,.png,.gif">
					<img v-if="imageUrl" :src="imageUrl" :onerror="imgSrc" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					<br/>图片建议尺寸: 320*320像素
				</div>
			</el-form-item>
			<el-form-item label="工程详情" prop="content">
				<tinyMce :content="form.content" :change.sync="form.content" />
			</el-form-item>
			<div class="submit-wrap">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import tinyMce from '@/components/plug/tinyMce'
	export default {
		data() {
			let validatePhoto = (rule, value, callback) => {
				if(this.imageUrl == "" || this.imageUrl == undefined || this.imageUrl == null){
					return callback(new Error('请上传封面图'));
				}else{
					callback()
				}
			}
			return {
				loading:false,
				imgSrc:'this.src="' + require('../../assets/img/mr.jpg') + '"',
				fileInfo:{
					type:"Member",
				},
				form: {
					id: '',
					title: '',
					keywords: '',
					description: '',
					catid: '',
					mycatid: '',
					photo: '',
					content: ''
				},
				options: [],
				options2: [],
				catidProps: {
					value: 'id',
					label: 'catname',
					children: 'child',
				},
				catidProps2: {
					value: 'id',
					label: 'catname',
					children: 'child',
				},
				imageUrl: '',
				rules: {
					title: [{
						required: true,
						message: '请输入标题'
					}],
					catid: [{
						required: true,
						message: '请选择分类'
					}],
					imageUrl:[{
						validator: validatePhoto,
						required: true
					}],
					content: [{
						required: true,
						validator: (rule, value, callback) => {
							if (this.form.content === '') {
								callback(new Error('请输入详情'));
							} else {
								callback();
							}
						}
					}]
				},
				picUrl:''
			}
		},
		created() {
			this.$axios.post('/getcat').then(data => {
				this.options = data
			})
			this.$axios.post('/getsellercat').then(data => {
				data.forEach(item => {
					if (!item.child.length) {
						delete item.child
					}
				})
				this.options2 = data
				
			})
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			handleChange(data) {
				this.form.catid = data[data.length - 1]
			},
			handleChange2(data) {
				this.form.mycatid = data[data.length - 1]
			},
			change(event, file, fileList){
				this.loading = true;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			handleAvatarSuccess(res, file) {
				this.handleAvatar(res, file,5)
				this.loading = false;
				// if(res.code == 200){
				// 	this.loading = false;
				// 	this.form.photo = res.data.src
				// 	this.imageUrl = URL.createObjectURL(file.raw);
				// }
			},
			goAddCatid() {
				this.$router.push('/myclass')
			},
			handleSubmit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$emit('eventForm', this.form)
					} else {
						return false
					}
				})
			}
		},
		components:{
			tinyMce
		},
		props: ['casesForm'],
		watch: {
			casesForm(val) {
				this.form = val
				this.imageUrl = this.form.photo ? this.$store.state.IMG_URL + '/' + this.form.photo :''
			}
		}
	}
</script>

<style scoped>
	.old-form>>>.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.old-form>>>.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.old-form>>>.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.old-form>>>.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.old-form .el-cascader {
		width: 350px;
	}

	.add-catid {
		margin-left: 10px;
	}

	.submit-wrap {
		text-align: center;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
